module.exports = {
  search: function(xhr) {
    $('#gtm-storeLocator-stores').attr('data-gtm-stores',xhr.responseJSON.gtmStoreLocatorData);
    $('body').trigger('gtm:storeLocator:search', xhr.responseJSON);
    $('body').trigger('gtm:storeLocator:searchCompleted');
  },
  save: function(xhr) {
    if (xhr.responseJSON.isRemoveStore) {
      $('body').trigger('gtm:storeLocator:remove', xhr.responseJSON);
    } else {
      $('body').trigger('gtm:storeLocator:save', xhr.responseJSON);
    }
  }
}