
const steps = {};
const DROPDOWN_DEFAULT = '—';
let productName;
let swatchSelected;
const { pipe } = require('gtm/gtm/utils/utils');

const getLastCompletedStep = function(steps) {
  let lastKey;
  let firstStepKey;
  let maxStepNumber = 0;

  for (const key in steps) {
    let step = steps[key];

    if (step.stepNumber === 1) {
      firstStepKey = key;
    }
    
    if (step.completed && step.stepNumber > maxStepNumber) {
      maxStepNumber = step.stepNumber;
      lastKey = key;
    }
  }

  return steps[lastKey || firstStepKey] ;
};

const handleSwatchSelected = function(e) {
  e.preventDefault();
  if (!swatchSelected) {
    return;
  }

  const dropdownId = $('.display-tile-container').attr('data-selected-id');

  const step = steps[dropdownId];
  const eventName = step.completed ? 'prodConfigOptionUpdated' : 'prodConfigOptionChosen';
  
  //Update step completition
  if (step) {
    step.completed = true; 
  }  

  const data = {
    eventName,
    step,
    productName,
    optionSelected: swatchSelected
  };

  $('body').trigger('gtm:configurator:stepCompletion', data);
}

const handleFormLoad = function() {
  $(".dropdowns-wrapper .custom-dropdown-container").each(function(index, value){
    const dropdown = $(this);
    const label = dropdown.data('dropdownName');
    const dropdownToggle = dropdown.find('.dropdown-toggle');
    let completed;
    let optionSelected;

    if ( dropdownToggle && dropdownToggle.length > 0 ){
      optionSelected =  $(dropdownToggle).text().trim() || '';
      completed = optionSelected !== DROPDOWN_DEFAULT && optionSelected.length > 1;
    }

    steps[dropdown.data('dropdownId')] = {
      stepName: label || 'n/a',
      stepNumber : index + 1,
      completed
    };

    if (completed && optionSelected.length > 0) {
      const data = {
        eventName: 'prodConfigOptionChosen',
        step: steps[dropdown.data('dropdownId')],
        optionSelected,
        productName
      };

      $('body').trigger('gtm:configurator:stepCompletion', data);
    } 

  });
};

const handleStepBack = function(step, optionSelected) {
  const data = {
    eventName: 'prodConfigOptionUpdated',
    step,
    productName,
    optionSelected
  }
  
  $('body').trigger('gtm:configurator:stepBack', data);

  //refresh completed step
  step.completed = false;
};

function handleStepChange(event) {
  const $elem = $(this);
  const parent = $(this).closest('.custom-dropdown-container');
  const id = $(parent).length > 0 ? $(parent).data('dropdownId') : $elem.data('dropdownId');
  const step = steps[id];
  const optionSelected = $elem.text() || $elem.val();

  if (!step) {
    return;
  }

  if (!step.completed) {
    const data = {
      eventName: 'prodConfigOptionChosen',
      step,
      productName,
      optionSelected
    }

    $('body').trigger('gtm:configurator:stepCompletion', data);
    
    //Update step completition
    step.completed = true;
  } else {
    handleStepBack(step, optionSelected);
  }
};

const handleDropdownStepChange = function () {
  $('.dropdowns-wrapper').on("click",'.dropdown-menu .dropdown-item', handleStepChange);
};

const handleInputStepChange = function() {
  $('.dropdowns-wrapper').on("change",'input.dropdown-toggle' , handleStepChange);
};

const handleClouseOut = function(){
  $(window).bind('beforeunload', function(e){
    const data = {
      eventName: 'prodConfig',
      step: getLastCompletedStep(steps),
      productName
    }

    $('body').trigger('gtm:configurator:closeOut', data);
  });
  
  $('form.registration').submit(function (e) {
    //unbind closeOut event
    $(window).unbind('beforeunload');
  });
};

const handleSubmitReview = function () {
  $('.configurator-form .save-configurator').click(function(){
    const data = {
      eventName: 'prodConfig',
      productName
    }

    //unbind closeOut event
    $(window).unbind('beforeunload');

    $('body').trigger('gtm:configurator:success', data);
  })
};

const handleSwatchSelection = function(){
  $('.display-tile-container').on('click', '.swatch-modal', function(){
    const swatch = $(this).find('.item-name');
    if (swatch && swatch.length > 0) {
      swatchSelected = swatch.text();
    } 
  });

  $(document).find('.select-swatch').on('click', handleSwatchSelected);
  $(document).on('submit', '.custom-swatch-form', handleSwatchSelected);
}


const handle = function() {
  pipe(
    handleFormLoad(),
    handleDropdownStepChange(),
    handleInputStepChange(),
    handleClouseOut(),
    handleSubmitReview(),
    handleSwatchSelection()
  );
};

module.exports = {
  attach: function() {
    const $configurator = $('.configurator-page');
    if ($configurator.is('*')) {
      $(document).ready(function(){
        const productsJson = $('#gtm-pdp-products').attr('data-gtm-products');
        const products =  productsJson ? JSON.parse(productsJson) : [];
        productName = (products[0] || {}).name || 'n/a';
  
        const data = {
          eventName: 'prodConfig',
          productName: productName
        };
  
        $('body').trigger('gtm:configurator:starts', data);
        
        //Call handle
        handle();
      });
    }
  }
}