module.exports = {
    handle: (e, data) => {           
        const {
            gtmData: {
                currencyCode,
                product: {
                    name,
                    id,
                    price,
                    brand,
                    category,
                    variant,
                    quantity
                }
            }
        } = data;

        dataLayer.push({ ecommerce: null });
        dataLayer.push({
            'event': 'removeFromCart',
            'ecommerce': {
                'currencyCode': currencyCode,
                'remove': {        
                    'products': [{
                        'name': name,       
                        'id': id,
                        'price': price,
                        'brand': brand,
                        'category': category,
                        'variant': variant,
                        'quantity': quantity
                    }]
                }
            }
        });           
    }
}