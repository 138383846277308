module.exports = {
  attach: function() {
    $('body').on('click', '.product', function() {
      var clickedProductId = $(this).data('pid').toString();
      var products = $('#gtm-pdp-products').attr('data-gtm-products');
      var productClicked = JSON.parse(products).filter(p => p.id === clickedProductId || p.variantId === clickedProductId);
      productClicked = productClicked && productClicked.length > 0 ? productClicked[0] : null; 
 
      $('body').trigger('gtm:eec:productClicks', productClicked);
    })
  }
}