module.exports = {
    handle: (e, data) => {           
        if (data.gtmData) {
            const {
                gtmData: {
                    products,
                    actionField,
                }
            } = data;

            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                'event': 'purchase',
                'ecommerce': {
                    'purchase': {
                        'actionField': actionField,
                        'products': products,
                    }
                }
            });
        }
    }
}