module.exports = {
    handle: (e, data) => {
        let $bc = $('.breadcrumb'),
            $toplevel = $bc.find('li:first-child'),
            $cat = $bc.find('li:last-child');

        if ( /portfolio/i.test($toplevel.text()) ) {
            dataLayer.push({
                event: 'portfolioView',
                portfolioName: `${$toplevel.text() > $cat.text()}`
            })
        }
    }
}