module.exports = {
  handle: (e, storeId) => {  
    const jsonStores = $('#gtm-storeLocator-stores').attr('data-gtm-stores');
    let stores = [];
    if (jsonStores) {
      stores = JSON.parse(jsonStores);
    }

    const store = stores.find( store => store.id === storeId);
    
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
        event: 'e.storeInfo',
        storeName: store.name,
        resultsPosition: store.position || 1,
        locatorAction: 'directions',
    });
  }
}