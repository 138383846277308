const actions = {
  ACCOUNT_SUBMITREGISTRATION: 'Account-SubmitRegistration',
  CART_REMOVEITEM: 'Cart-RemoveProductLineItem',
  CHECKOUT_SUBMITSHIPPING: 'CheckoutShippingServices-SubmitShipping',
  CHECKOUT_SUBMITPAYMENT: 'CheckoutServices-SubmitPayment',
  CHECKOUT_PLACEORDER: 'CheckoutServices-PlaceOrder',
  STORELOCATOR_FINDSTORES: 'Stores-FindStores',
  STORELOCATOR_SAVEASPREFERRED: 'Stores-SaveAsPreferred',
  SEARCH_UPDATEGRID: 'Search-UpdateGrid'
}

module.exports = actions;