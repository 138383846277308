const steps = {};
const { pipe } = require('../utils/utils');

const getLastCompletedStep = function(steps) {
  let lastKey;
  let maxStepNumber = 0;

  for (const key in steps) {
    let step = steps[key];
    if (step.completed && step.stepNumber > maxStepNumber) {
      maxStepNumber = step.stepNumber;
      lastKey = key;
    }
  }

  return steps[lastKey];
};

const handleFormLoad = function() {
  $(".registration input:visible").each(function(index, value){
    const input = $(this);
    const label = $("label[for='" + $(this).attr('id') + "']");

    steps[input.attr('id')] = {
      stepName: label.text().trim() || 'n/a',
      stepNumber : index + 1
    }
  });
}

const handleStepBack = function(step) {
  const data = {
    eventName: 'register',
    step: step
  }
  
  $('body').trigger('gtm:funnels:stepBack', data);

  //refresh completed step
  step.completed = false;
}

const handleStepChange = function() {
  $('.form-control').change(function() {
    console.log('steps changed:', steps);
    const input = $(this)
    const step = steps[input.attr('id')];

    if (!step) {
      return;
    }

    if (!step.completed) {
      const data = {
        eventName: 'register',
        step: step
      }

      $('body').trigger('gtm:funnels:stepCompletion', data);
      
      //Update step completition
      step.completed = true;
    } else {
      handleStepBack(step);
    }
    
  });
}

const handleClouseOut = function(){
  $(window).bind('beforeunload', function(e){
    const data = {
      eventName: 'register',
      step: getLastCompletedStep(steps)
    }

    $('body').trigger('gtm:funnels:closeOut', data);
  });
  
  $('form.registration').submit(function (e) {
    //unbind closeOut event
    $(window).unbind('beforeunload');
  });
}

const handle = function() {
  pipe(
    handleFormLoad(),
    handleStepChange(),
    handleClouseOut()
  );
}

module.exports = {
  attach: function() {
    $('#register-tab').on('shown.bs.tab' ,function() {
      //Funnel Start
      $('body').trigger('gtm:funnels:starts', 'register');
      
      //Call handle
      handle();
    })
  }
}
