module.exports = {
  submitShipping: function(xhr, event){
   $('body').trigger('gtm:eec:checkoutOption', xhr.responseJSON);
   event.attach(xhr.responseJSON);
  },
  submitPayment: function(xhr, event) {
    $('body').trigger('gtm:eec:checkoutOption', xhr.responseJSON);
    event.attach(xhr.responseJSON);
  },
  placeOrder : function(xhr) {
    $('body').trigger('gtm:eec:purchase', xhr.responseJSON);
  }
}