var pageData = window.dataLayer[0];

module.exports = {
    handle: (e, products) => {           
        if (!products) {
            return;
        }
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        dataLayer.push({
            event: 'productDetail',
            ecommerce: {
                detail: {
                    actionField: { list: pageData.list},
                    products: JSON.parse(products)
                }
            }
        });
    }
}