const actions = {
  STORE_FIND: 'Stores-Find'
};

function setLocalStore($elem) {
  let position;
  const storeId = getStoreId($elem);

  if (!storeId) {
    return;
  }

  const jsonStore = $('#gtm-storeLocator-stores').attr('data-gtm-stores');

  if (jsonStore) {
    const stores  = JSON.parse(jsonStore);
    position = (stores.find(store => store.id === storeId) || {}).position || 1;
  }

  localStorage.setItem(storeId, position);
}

function getStoreId($elem) {
  const storeFormId = $elem.closest('form').attr('id');
  let id;
  if (storeFormId && storeFormId.length) {
    let splitted  = storeFormId.split('-');
    id = splitted[1];
  }
  return id;
}

module.exports = {
  attach: function() {
    const $storeLocator = $('.page').data('action');
    if ($storeLocator === actions.STORE_FIND) {
      $('body').trigger('gtm:storeLocator:start');
    } 
    
    const $storeDetail = $('.store-details');
    if ($storeDetail.is('*')) {
      $('body').trigger('gtm:storeLocator:details');
    }
  },
  attachInfo: function() {
    $(".store-card").click(function() {
      setLocalStore($(this));
    })

    $(".store-card .store-card__footer-directions").click(function() {
      const storeId = getStoreId($(this));
      $('body').trigger('gtm:storeLocator:directions', storeId);
    }); 
  }
}