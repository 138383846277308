module.exports = {
  handle: (e, data) => {  
    if (data && data.success) {
      const store = data.storeTemplate;
      const position = localStorage.getItem(store.id);
    
      dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      dataLayer.push({
          event: 'e.removeStore',
          storeName: store.name,
          resultsPosition: Number(position) || 1,
          locatorAction: 'remove',
      });
    } 
  }
}