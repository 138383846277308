module.exports = {
  handle: (e, data) => {           
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: `e.${data.eventName}Close`,
        productName: data.productName,
        stepName: (data.step || {}).stepName || 'n/a',
        stepNumber: (data.step || {}).stepNumber || 1
    });       
  }
}