'use strict';

class PageLoadEvents {
    static portfolio() {
        let $bc = $('.breadcrumb'),
            $toplevel = $bc.find('li:first-child a.d-md-block'),
            $cat = $bc.find('.breadcrumb-last');

        if ( /portfolio/i.test($toplevel.text()) ) {
            dataLayer.push({
                event: 'portfolioView',
                portfolio_name: `${$toplevel.text().trim()} > ${$cat.text().trim()}`
            })
        }
    }
}

module.exports = {
    handle: (e, data) => {
        data && PageLoadEvents[data.handler]();
    }
}