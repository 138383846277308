'use strict';

const productImpressionsHandler = require('./eventHandlers/eecHandlers/productImpressions');
const productClicksHandler = require('./eventHandlers/eecHandlers/productClicks');
const productDetailsView = require('./eventHandlers/eecHandlers/productDetailsView');
const addToCartHandler = require('./eventHandlers/eecHandlers/addToCart');
const updateItemHandler = require('./eventHandlers/eecHandlers/updateItem');
const removeFromCartHandler = require('./eventHandlers/eecHandlers/removeFromCart');
const checkoutHandler = require('./eventHandlers/eecHandlers/checkout');
const checkoutOptionHandler = require('./eventHandlers/eecHandlers/checkoutOption');
const purchaseHandler = require('./eventHandlers/eecHandlers/purchase');
const internalPromotionImpressionsHandler = require('./eventHandlers/eecHandlers/internalPromotionImpressions');
const internalPromotionClicksHandler = require('./eventHandlers/eecHandlers/internalPromotionClicks');
const pageLoadHandler = require('./eventHandlers/pageLoad');
const quoteHandler = require('./eventHandlers/quote');
const newsletterHandler = require('./eventHandlers/newsletter');

const funnelStartsHandler = require('./eventHandlers/funnelHandlers/starts');
const funnelStepBackHandler = require('./eventHandlers/funnelHandlers/stepBack');
const funnelStepCompletionHandler = require('./eventHandlers/funnelHandlers/stepCompletion');
const funnelSuccessHandler = require('./eventHandlers/funnelHandlers/success');
const funnelCloseOutHandler = require('./eventHandlers/funnelHandlers/closeOut');

const applicationStepCompleteHandler = require('./eventHandlers/applicationEvents/stepCompletion');
const applicationAbandonHandler = require('./eventHandlers/applicationEvents/abandon');

//Custom Funnels
const storeLocatorStartHandler = require('./eventHandlers/storeLocator/storeLocatorStart');
const storeLocatorSearchHandler = require('./eventHandlers/storeLocator/storeLocatorSearch');
const storeLocatorDetailsHandler = require('./eventHandlers/storeLocator/storeLocatorDetails');
const storeLocatorDirectionsHandler = require('./eventHandlers/storeLocator/storeLocatorDirections');
const storeLocatorSaveHandler = require('./eventHandlers/storeLocator/storeLocatorSave');
const storeLocatorRemoveHandler = require('./eventHandlers/storeLocator/storeLocatorRemove');


const searchHandler = require('./eventHandlers/search/search');
const ajaxResponses = require('./ajaxResponses/index');

//Custom Events
const attachedProductDetailView = require('./attachedEvents/productDetailView');
const attachedProductClick = require('./attachedEvents/productClick');
const attachedProductImpressions = require('./attachedEvents/productImpressions');
const attachedCheckout = require('./attachedEvents/checkout');
const attachedStoreLocator = require('./attachedEvents/storeLocator');
const attachedRegister = require('./attachedEvents/register');
const attachedCTA = require('./attachedEvents/cta');
const attachedConfigurator = require('./attachedEvents/configurator');

//Configurator Events
const configuratorStartHandler = require('./configuratorHandlers/starts');
const configuratorStepBackHandler = require('./configuratorHandlers/stepBack');
const configuratorStepCompletionHandler = require('./configuratorHandlers/stepCompletion');
const configuratorSuccessHandler = require('./configuratorHandlers/success');
const configuratorCloseOutHandler = require('./configuratorHandlers/closeOut');

const handleBuiltInEvents = () => {
    $('body').on('product:afterAddToCart', function (e, data) {
        $('body').trigger('gtm:eec:addToCart', data);
    });

    ajaxResponses.handle();
}

const handleCustomEvents = () => {
    attachedProductDetailView.attach();
    attachedProductClick.attach();
    attachedProductImpressions.attach();
    attachedCheckout.attach();
    attachedStoreLocator.attach();
    attachedRegister.attach();
    attachedCTA.attach();
    attachedConfigurator.attach();
}

const registerPageLoadEvents = () => {
    $('body').on('gtm:pageLoad', pageLoadHandler.handle);
};

const registerEecEvents = () => {
    $('body').on('gtm:eec:productImpressions', productImpressionsHandler.handle);
    $('body').on('gtm:eec:productClicks', productClicksHandler.handle);
    $('body').on('gtm:eec:productDetailsView', productDetailsView.handle);
    $('body').on('gtm:eec:addToCart', addToCartHandler.handle);
    $('body').on('gtm:eec:updateItem', updateItemHandler.handle);
    $('body').on('gtm:eec:removeFromCart', removeFromCartHandler.handle);
    $('body').on('gtm:eec:checkout', checkoutHandler.handle);
    $('body').on('gtm:eec:checkoutOption', checkoutOptionHandler.handle);
    $('body').on('gtm:eec:purchase', purchaseHandler.handle);
};

const registerQuoteEvents = () => {
    $('body').on('gtm:quote', quoteHandler.handle);
};

const registerNewsletterEvents = () => {
    $('body').on('gtm:newsletter:signup', newsletterHandler.handle);
}

const registerOptionalEecEvents = () => {
    $('body').on('gtm:eec:internalPromotionImpressions', internalPromotionImpressionsHandler.handle);
    $('body').on('gtm:eec:internalPromotionClicks', internalPromotionClicksHandler.handle);
};

const registerFunnelsEvents = () => {
    $('body').on('gtm:funnels:starts', funnelStartsHandler.handle);
    $('body').on('gtm:funnels:success', funnelSuccessHandler.handle);
    $('body').on('gtm:funnels:stepCompletion', funnelStepCompletionHandler.handle);
    $('body').on('gtm:funnels:stepBack', funnelStepBackHandler.handle);
    $('body').on('gtm:funnels:closeOut', funnelCloseOutHandler.handle);
};

const registerCustomFunnels = () => {
    if ($('#gtm-storeLocator-stores').length) {
        $('body').on('gtm:storeLocator:start', storeLocatorStartHandler.handle);
        $('body').on('gtm:storeLocator:search', storeLocatorSearchHandler.handle);
        $('body').on('gtm:storeLocator:searchCompleted', attachedStoreLocator.attachInfo);
        $('body').on('gtm:storeLocator:details', storeLocatorDetailsHandler.handle);
        $('body').on('gtm:storeLocator:directions', storeLocatorDirectionsHandler.handle);
        $('body').on('gtm:storeLocator:save', storeLocatorSaveHandler.handle);
        $('body').on('gtm:storeLocator:remove', storeLocatorRemoveHandler.handle);
    }
};

const registerConfiguratorEvents = () => {
    $('body').on('gtm:configurator:starts', configuratorStartHandler.handle);
    $('body').on('gtm:configurator:success', configuratorSuccessHandler.handle);
    $('body').on('gtm:configurator:stepCompletion', configuratorStepCompletionHandler.handle);
    $('body').on('gtm:configurator:stepBack', configuratorStepBackHandler.handle);
    $('body').on('gtm:configurator:closeOut', configuratorCloseOutHandler.handle);
};

const registerSearchEvents = () => {
    $('body').on('gtm:search', searchHandler.handle);
};

const registerApplicationEvents = () => {
    $('body').on('gtm:application:stepCompleted', applicationStepCompleteHandler.handle);
    $('body').on('gtm:application:abandon', applicationAbandonHandler.handle);
};

const configureFooter = () => {
    $('footer a').addClass('ga_footerNav');
};

const triggerQueuedEvents = () => {
    if (window.queuedGtmEvents) {
        window.queuedGtmEvents.forEach(ev => {
            $('body').trigger(ev.name, ev);
        });
    }
}

$(document).ready(() => {
    configureFooter();

    registerPageLoadEvents();
    registerQuoteEvents();
    registerSearchEvents();
    registerFunnelsEvents();
    registerCustomFunnels();
    registerConfiguratorEvents();
    registerEecEvents();
    registerOptionalEecEvents();
    registerApplicationEvents();
    registerNewsletterEvents();

    handleBuiltInEvents();
    handleCustomEvents();

    triggerQueuedEvents();
});