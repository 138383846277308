module.exports = {
    handle: (e, response, step) => { 
        let products = response ? JSON.parse(response) : [];          
        const checkoutURL = $('#gtm-checkout-products').attr('data-gtm-checkoutURL');

        dataLayer.push({ ecommerce: null });
        dataLayer.push({
            event: 'checkout',
            ecommerce: {
                checkout: {
                    actionField: {
                        step: step || 1,
                    },
                    products: products
                },
                eventCallback: function() {
                    document.location = checkoutURL;
                }
            }
        });
    }
}