module.exports = {
    handle: (e, data) => {           
        if (data.gtmData) {

            const {
                gtmData: {
                    currencyCode,
                    product: {
                        name,
                        id,
                        price,
                        brand,
                        category,
                        variant,
                        quantity
                    }
                }
            } = data;

            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                'event': 'addToCart',
                'ecommerce': {
                    'currencyCode': currencyCode,
                    'add': {        
                        'products': [{
                            'name': name,       
                            'id': id,
                            'price': price,
                            'brand': brand,
                            'category': category,
                            'variant': variant,
                            'quantity': quantity
                        }]
                    }
                }
            });
        }  
    }
}
