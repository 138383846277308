'use strict';

class QuoteEvents {
    static save(data) {
        window.dataLayer.push({
            event: 'quoteSave',
            quote_id: data.quote_id
        });
    }
}

module.exports = {
    handle: (e, data) => {
        data && QuoteEvents[data.handler](data);
    }
}