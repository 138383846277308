var pageData = window.dataLayer[0];

module.exports = {
    handle: (e, product) => {  
        //Removed variantId
        let {productURL, variantId, ...newProduct} = product

        dataLayer.push({ ecommerce: null });
        dataLayer.push({
            event: 'productClick',
            ecommerce: {
                click: {
                    actionField: { list:  pageData.list},
                    products: newProduct
                },
                eventCallback:() => (
                    document.location = productURL
                ),
                eventTimeout : 2000
            },
        });
    }
}