module.exports = {
  updateGrid: function(xhr) {
    const $searchResults = $('#gtm-viewMore-products');
    if ($searchResults.length) {
      const updatedProducts = $('#gtm-viewMore-products').attr('data-search-products');
      $('#gtm-pdp-products').attr('data-gtm-products', updatedProducts);
      
      //Call Impressions
      $('body').trigger('gtm:eec:productImpressions', updatedProducts);
      
      //Removed dynamic search results data
      $('#gtm-viewMore-products').remove();
    }
  }
}