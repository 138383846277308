var pageData = window.dataLayer[0];

module.exports = {
    handle: (e, products) => {  
        const impressions = products ? JSON.parse(products) : [];
        const currencyCode = impressions && impressions.length ? impressions[0].currencyCode : '';
        const list = pageData.list;

        //Removed productURL, variantId & currencyCode
        const newImpressions = impressions.map(({productURL, variantId, currencyCode,...props}) => ({...props, list}));

        dataLayer.push({ ecommerce: null });
        dataLayer.push({
            event: 'productImpression',
            ecommerce: {
                currencyCode: currencyCode,
                impressions: newImpressions
            },
        });
    }
}