const accountHandler = require('./account');
const cartAjaxHandler = require('./cart');
const checkoutAjaxHandler = require('./checkout'); 
const storeLocatorAjaxHandler = require('./storeLocator');
const searchAjaxHandler = require('./search');
const actions = require('./actions');

const attachedCheckout = require('../attachedEvents/checkout');

function getActionPathName(url) {
    if (!url) {
        return '';
    }
    return url.substring(url.lastIndexOf("/") + 1 , url.lastIndexOf('?'));
}

module.exports = {
    handle: function() {
        $(document).ajaxSuccess(function(event, xhr, settings) {
            let handler;
            let disableHandler;
          
            const { 
                ACCOUNT_SUBMITREGISTRATION,
                CART_REMOVEITEM,
                CHECKOUT_SUBMITSHIPPING,
                CHECKOUT_SUBMITPAYMENT,
                CHECKOUT_PLACEORDER,
                STORELOCATOR_FINDSTORES,
                STORELOCATOR_SAVEASPREFERRED,
                SEARCH_UPDATEGRID
            } = actions;

            const actionHandler = {
                [ACCOUNT_SUBMITREGISTRATION]: () => accountHandler.submitRegistration(xhr),
                [CART_REMOVEITEM]: () => cartAjaxHandler.removeFromCart(xhr),
                [CHECKOUT_SUBMITSHIPPING]: () => checkoutAjaxHandler.submitShipping(xhr, attachedCheckout),
                [CHECKOUT_SUBMITPAYMENT]: () => checkoutAjaxHandler.submitPayment(xhr, attachedCheckout),
                [CHECKOUT_PLACEORDER]: () => checkoutAjaxHandler.placeOrder(xhr),
                [SEARCH_UPDATEGRID]:() => searchAjaxHandler.updateGrid(xhr),
                [STORELOCATOR_FINDSTORES]: () => storeLocatorAjaxHandler.search(xhr),
                [STORELOCATOR_SAVEASPREFERRED]: () => storeLocatorAjaxHandler.save(xhr)
            }

            if (xhr.responseJSON && xhr.responseJSON.action) {
                handler = actionHandler[xhr.responseJSON.action];
                disableHandler = xhr.responseJSON.disableHandler;
            } else {
                const actionURL = getActionPathName(settings.url);
                handler = actionHandler[actionURL];
            }

            if (handler && !disableHandler) {
                handler();
            }
        });
    }
}