module.exports = {
  handle: (e, response) => { 
    const { gtmData: { currentStep, option } = {} } = response || {};
    
    dataLayer.push({ ecommerce: null });
    // Steps: Shipment = 1, payment = 2, Place Order = 3, submit order=4
    dataLayer.push({
      event: 'checkoutOption',
      ecommerce: {
        checkout_option: {
            actionField: {
                step: currentStep || 1,
                option: option || 'N/A'
            }
          }  
      }
    });
  }
}