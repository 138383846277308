module.exports = {
  handle: (e, data) => {           
      dataLayer.push({ ecommerce: null });
      dataLayer.push({
          event: `e.${data.eventName}`,
          stepName: (data.step || {}).stepName || 'n/a',
          stepNumber: (data.step || {}).stepNumber || 1,
          productName: data.productName,
          optionSelected: data.optionSelected
      });          
  }
}