module.exports = {
  submitRegistration: function(xhr) {
    const response = xhr.responseJSON;
    if (response.success) {
      const data = {
        eventName: 'register',
        customerId: response.customerId
      }
      
      $('body').trigger('gtm:funnels:success', data);
    }
  }
}