'use strict';

class NewsletterEvents {
    static signup(data) {
        window.dataLayer.push({
            event: 'newsletterSignup'
        });
    }
}

module.exports = {
    handle: (e, data) => {
        data && NewsletterEvents[data.handler](data);
    }
}