const CTA_TYPES = {
  LINK: 'LINK',
  H1: 'H1',
  IMG: 'IMG'
}

const handleH1 = (elem) => {
  const ctaText = $(elem).text().trim();
  return { 
    ctaText
  };
};

const handleImg = (elem) => {
  const ctaText = $(elem).is("[title]") ? $(elem).attr('title') : $(elem).attr('alt');
  return {
    ctaText
  };
};

const ctaHandlers = {
  [CTA_TYPES.H1]: (param) => handleH1(param),
  [CTA_TYPES.IMG]: (param) => handleImg(param),
}


const handleChilds = (elem) => {
  let child = $(elem).children()[0]; 
  if ($(child).children().length > 0) {
    child = $(child).children()[0];
  }

  const ctaType = $(child).prop('nodeName');
  let ctaResult;

  if (child && ctaType) {
    ctaResult = ctaHandlers[ctaType] ? ctaHandlers[ctaType](child) : null;
  }

  return {
    ctaType,
    ctaResult
  };
}

module.exports = {
  attach: function() {
    $("#maincontent a").each(function(index) { 
      const anchor = $(this); 
     
      $(anchor).addClass('ga_CTA');  
      $(anchor).attr('data-ctaposition', index + 1);
   
      if ($(anchor).children().length > 0) {
        const { ctaType, ctaResult} = handleChilds(anchor);
        $(anchor).attr('data-ctatype', ctaType);
        $(anchor).attr('data-ctatext', ctaResult ? ctaResult.ctaText : '');
   
      } else {
        $(anchor).attr('data-ctatype', CTA_TYPES.LINK);
        $(anchor).attr('data-ctatext', $(anchor).text().trim());
      }
    })
  }
}