module.exports = {
  handle: (e) => {  
    const jsonStore = $('#gtm-storeLocator-stores').attr('data-gtm-stores');
    if (jsonStore) {
      store = JSON.parse(jsonStore);
    }
    const position = localStorage.getItem(store.ID);
    
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
        event: 'e.storeInfo',
        storeName: store.name,
        resultsPosition: Number(position) || 1,
        locatorAction: 'details',
    });
  }
}